import React, { useEffect, useState } from 'react'
import { FileDetailsObject } from '../../../models/FileDetails'
import FileManagerOperations from '../Services/FileManagerOperations';
import { ActionType, ProcessorType, getImageSrc, isDocumentProcessorSupported, isDocumentSupportedForAnyProcessor } from '../../../utilities/SupportedFileFormats';
import { FileItem } from '../../../models/FileItem';
import { fileOpenService } from '../../../apis/fileService';
import { FileSession } from '../../../models/FileSession';
import { PdfJsPreviewer } from '../PdfJsPreviewer'; import FileManagerRichTextEditorPreview from '../DocumentEditors/FileManagerRichTextEditorPreview';
import FileManagerSpreadsheetPreviewer from '../DocumentEditors/FileManagerSpreadsheetPreviewer';
import FileManagerDocumentPreviewer from '../DocumentEditors/FileManagerDocumentPreviewer';
import FileManagerPDFPreviewer from '../DocumentEditors/FileManagerPDFPreviewer';

interface Props {
    FileManagerOperations: FileManagerOperations;
    FileSession: FileSession;
}

export default function FilePreview({ FileManagerOperations, FileSession }: Props) {
    const [FileDetailsObject, setFileDetailsObject] = useState<FileDetailsObject | null>();
    const [currentCache, setCurrentCache] = useState<FileItem[]>([]);
    const [currentFileName, setCurrentFileName] = useState("");
    const [currentFileId, setCurrentFileId] = useState("");
    const [resizeToken, setResizeToken] = useState(0);

    useEffect(() => {

        const handleSelectedGridItem = () => {
            const selectedItem = FileManagerOperations.selectedGridViewItems?.at(0);
            setFileDetailsObject(selectedItem);
            setCurrentFileName(selectedItem?.name ?? "");
            setCurrentFileId(selectedItem?.id ?? "");
            if (selectedItem) {
                loadFileBytes(selectedItem?.id ?? "", selectedItem?.name ?? "", selectedItem?.fileProviderId ?? "");
            }
        };

        const handleSelectedTreeItem = () => {
            const selectedItem = FileManagerOperations.selectedTreeViewItem;
            setFileDetailsObject(FileManagerOperations.selectedTreeViewItem);
            setCurrentFileName(selectedItem?.name ?? "");
            setCurrentFileId(selectedItem?.id ?? "");

        };

        const handleResizeToken = () => {
            setResizeToken(FileManagerOperations.resizeToken);
        }

        // Subscribe to selection change events in FileManagerOperations
        FileManagerOperations.on('gridViewSelectionChanged', handleSelectedGridItem);
        FileManagerOperations.on('treeViewSelectionChanged', handleSelectedTreeItem);
        FileManagerOperations.on('resizeTokenChanged', handleResizeToken);
        // Clean up event listeners when component unmounts
        return () => {
            FileManagerOperations.off('gridViewSelectionChanged', handleSelectedGridItem);
            FileManagerOperations.off('treeViewSelectionChanged', handleSelectedTreeItem);
            FileManagerOperations.off('resizeTokenChanged', handleResizeToken);
        };
    }, [FileManagerOperations]);

    const loadFileBytes = async (id: string, name: string, provider: string) => {
        const fileItem = currentCache.find(fileItem => fileItem.id === id);
        if (!fileItem) {
            const result = await fileOpenService(id, name, provider ?? "", FileSession);
            var data = result?.data?.Data;
            if (data) {
                const newFileItem = new FileItem(
                    data.Id.toString(),
                    data.FileName,
                    data.FileBase64String,
                    data.RelativePath,
                    data.currentCache?.length ?? 0,
                    data.ChildJson,
                    data.ParentJson,
                    data.ProviderId
                );

                setCurrentCache(prevFileItems => {
                    const newCache = [newFileItem, ...prevFileItems];
                    // If the cache size exceeds 5, remove the oldest items
                    return newCache.length > 5 ? newCache.slice(0, 5) : newCache;
                });
            }
        }
    };

    const getBase64String = (fileId: string) => {
        const fileItem = currentCache.find(fileItem => fileItem.id === fileId);
        return fileItem?.base64String || "";
    };

    return (

        <div style={{ backgroundColor: '#3f3f3f' }} >
            <div className="sidebar-child" >
                <div className="e-card"
                    id="#preview-pane"
                    style={{
                        backgroundColor: '#3f3f3f',
                        height: '86vh',
                        padding: '5px',
                        visibility: `${(FileDetailsObject && FileDetailsObject.isFile
                            && isDocumentSupportedForAnyProcessor(FileDetailsObject?.name, ActionType.Preview)) ?
                            `visible` : `hidden`}`
                    }}>
                    {
                        (FileDetailsObject &&
                            currentCache.find(
                                fileItem =>
                                    fileItem.id ===
                                    FileDetailsObject.id)?.base64String && FileDetailsObject?.isFile && currentFileName
                            && (
                                isDocumentProcessorSupported(FileDetailsObject?.name, ProcessorType.Image, ActionType.Preview)
                            )) &&

                        <img
                            src={
                                (FileDetailsObject &&
                                    getImageSrc(FileDetailsObject?.name ?? "",
                                        getBase64String(FileDetailsObject?.id)
                                    )) ?? ""
                            }
                            alt={FileDetailsObject?.name}
                            style={{
                                height: 'auto',
                                width: '100%',
                                aspectRatio: '1/1'
                            }}
                        />
                    }


                    {
                        (FileDetailsObject
                            && isDocumentProcessorSupported(FileDetailsObject?.name, ProcessorType.PDF, ActionType.Preview)
                            && FileDetailsObject?.isFile && currentFileName) &&

                        <FileManagerPDFPreviewer pdfPath={encodeURIComponent(currentFileId)}
                            fileId={encodeURIComponent(currentFileId)}
                            fileName={encodeURIComponent(currentFileName)}
                            fileSession={FileSession}
                            FileManagerOperations={FileManagerOperations}
                        />
                    }


                    {
                        (FileDetailsObject
                            && isDocumentProcessorSupported(FileDetailsObject?.name, ProcessorType.Text, ActionType.Preview)
                            && FileDetailsObject?.isFile && currentFileName) &&

                        <div style={{
                            height: '85vh',
                            width: '100%',
                            overflowY: 'auto',
                        }} >

                            <FileManagerRichTextEditorPreview
                                html={currentCache.find(fileItem => fileItem.id === FileDetailsObject.id)?.base64String} />
                        </div>
                    }

                    {
                        (FileDetailsObject
                            && (isDocumentProcessorSupported(FileDetailsObject?.name, ProcessorType.Excel, ActionType.Preview))
                            && FileDetailsObject?.isFile && currentFileName) &&


                        <FileManagerSpreadsheetPreviewer
                            base64FileString={currentCache.find(fileItem => fileItem.id === FileDetailsObject.id)?.base64String}
                            fileName={currentFileName}
                            fileSession={FileSession} />
                    }

                    {
                        (FileDetailsObject
                            && (
                                isDocumentProcessorSupported(FileDetailsObject?.name, ProcessorType.Word, ActionType.Preview)
                            )
                            && FileDetailsObject?.isFile && currentFileName) &&

                        <div style={{
                            'height': '86vh',
                            'width': '100%',
                            'overflowY': 'auto'
                        }} >
                            <FileManagerDocumentPreviewer
                                filePath={currentFileId}
                                fileName={currentFileName}
                                refresh={resizeToken}
                                fileSession={FileSession} />
                        </div>
                    }



                </div>

            </div>
        </div>

    )
}
