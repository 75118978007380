/* eslint-disable @typescript-eslint/no-unused-vars */
import { getAccessToken } from "../utilities/getTokenAndExpireTime";
declare global {
    interface Window {
        _env_: {
            API_URL: string;
            ODOO_HOST: string;
        };
    }
}

const odooHost = window._env_.ODOO_HOST;
const apiUrl = window._env_.API_URL;

//const odooHost = "http://localhost:3000";
//const apiUrl = "http://localhost:5000";

export const ODOO_URL = odooHost;

const getServerURL = () => {
    return `${apiUrl}/`;
}

const getFileServiceURL = () => {
    return `${apiUrl}/`;
}

const getPDFServiceURL = () => {
    return `${apiUrl}/api/pdfviewer`;
}

const getSpreadsheetServiceURL = () => {
    return `${apiUrl}/api/spreadsheetviewer/Open`;
}

const getSpreadsheetSaveServiceURL = () => {
    return `${apiUrl}/api/spreadsheetviewer/Save`;
}

const getStandalonePDFServiceURL = () => {
    return `https://cdn.syncfusion.com/ej2/25.1.35/dist/ej2-pdfviewer-lib`;
}

export const SERVER_URL = getServerURL();
export const SERVICE_URL = getFileServiceURL();
export const PDF_SERVICE_URL = getPDFServiceURL();
export const SPREADSHEET_SERVICE_URL = getSpreadsheetServiceURL();
export const STANDALONE_PDF_SERVICE_URL = getStandalonePDFServiceURL();
export const SPREADSHEET_SERVICE_URL_SAVE = getSpreadsheetSaveServiceURL();

export const REDIRECT_URL = window.location.href.includes("?") ? window.location.href.split("?")[0] : window.location.href;

export const getStoreSetupURL = () => {
    const domainArr = window.location.href.split("/");
    return `${domainArr[0]}//${domainArr[2]}/settings/store-setup`;
}

export const tokenCookieName = 'sv_tkn';
export const refreshTokenKey = 'refreshToken';
export const accesstokenSessionKey = 'accesstoken';
export const accesstokenSessionExpiryKey = 'expireAt';

export const getToken = () => {
    try {
        const token = getAccessToken(tokenCookieName);
        return token;
    } catch (err) {
        return "";
    }
}

export const config = { headers: { Authorization: `Bearer ${getToken()}` } };
// Use getAxiosConfig instead of config object as it will give you a stale config if the token in cookie/session storage changes
export const getAxiosConfig = () => ({ headers: { Authorization: `Bearer ${getToken()}` } });

export const APP_COLOR = {
    appLightBlue: "#3c8dbc",
    appHrGrey: "#e9ecef",
    deleteRed: "#D30101"
};
