import React, { useEffect, useRef, useState } from 'react'
import FileManagerOperations from '../Services/FileManagerOperations';
import { FileDetailsObject } from '../../../models/FileDetails';
import { ColumnChooser, ColumnDirective, ColumnsDirective, ContextMenu, ContextMenuClickEventArgs, ContextMenuItem, ContextMenuItemModel, ContextMenuOpenEventArgs, SelectionSettingsModel, Edit, Filter, GridComponent, Inject, Page, PageSettingsModel, Resize, Sort, Toolbar, ToolbarItems, RowDragEventArgs, RowDD, Group, RecordDoubleClickEventArgs, RowDataBoundEventArgs } from '@syncfusion/ej2-react-grids';
import "../BaseComponents/FileManagerDataGrid.scss"
import { FileSession } from '../../../models/FileSession';
import { ActionType, ProcessorType, getFileIconClass, isDocumentProcessorSupported } from '../../../utilities/SupportedFileFormats';
import { fileUploadService } from '../../../apis/fileService';

interface Props {
    FileManagerOperations: FileManagerOperations;
    FileSession: FileSession;
}

const formatSize = (size: number, isFolder: boolean): string => {
    if (isFolder) return "";

    if (size === 0) return '0 KB';
    const i = Math.floor(Math.log(size) / Math.log(1024));
    const units = ['B', 'KB', 'MB', 'GB'];

    // Format size with one decimal place for MB and GB, no decimals for B and KB
    const formattedSize =
        i < 2
            ? Math.floor(size / Math.pow(1024, i)) // No decimals for B and KB
            : (size / Math.pow(1024, i)).toFixed(1); // One decimal place for MB and GB

    return `${formattedSize} ${units[i]}`;
};

// Utility function to format date
const formatDate = (date: Date | string): string => {
    const parsedDate = typeof date === 'string' ? new Date(date) : date;
    return new Intl.DateTimeFormat('en-US', {
        dateStyle: 'medium',
        timeStyle: 'short',
    }).format(parsedDate);
};

export default function FileManagerDataGrid({ FileManagerOperations, FileSession }: Props) {
    const [gridData, setGridData] = useState<FileDetailsObject[]>([]);
    const gridRef = useRef<GridComponent>(null);
    const [pageSettings, setPageSettings] = useState<PageSettingsModel>();
    const [selectedFiles, setSelectedFiles] = useState<FileDetailsObject[]>([]);
    const [cutOrCopiedFiles, setCutOrCopiedFiles] = useState<string[]>([]);
    const [canPaste, setCanPaste] = useState<boolean>(false);
    const isFileAlreadyCutOrCopied = (fileId: string) => cutOrCopiedFiles.includes(fileId);

    useEffect(() => {
        setPageSettings({ pageSize: 50 });
    }, []);

    useEffect(() => {
        const fetchGridData = async () => {
            try {
                await FileManagerOperations.fetchGridViewItems();
                setGridData(FileManagerOperations['gridViewItems']);
            } catch (error) {
                console.error('Error fetching grid data:', error);
            }
        };

        fetchGridData();

        // Listen for updates to GridView
        const handleGridViewUpdate = () => {
            setGridData(FileManagerOperations.gridViewItems);
        };


        FileManagerOperations.on('gridViewUpdated', handleGridViewUpdate);
        return () => {
            FileManagerOperations.off('gridViewUpdated', handleGridViewUpdate);
        };
    }, [FileManagerOperations]);


    useEffect(() => {

        // Listen for clipboard to GridView
        const handleClipboardUpdates = () => {
            const ids = FileManagerOperations.clipboard.map(item => item.id);
            setCutOrCopiedFiles(ids);
        };

        const handleClipboardClear = () => {
            setCutOrCopiedFiles([]);
        };
        FileManagerOperations.on('clipboardUpdated', handleClipboardUpdates);
        FileManagerOperations.on('clipboardCleared', handleClipboardClear);

        return () => {
            FileManagerOperations.off('clipboardUpdated', handleClipboardUpdates);
            FileManagerOperations.off('clipboardCleared', handleClipboardClear);
        };
    }, [FileManagerOperations]);

    useEffect(() => {
        gridRef.current?.refresh();
    }, [cutOrCopiedFiles]);



    const fileIconTemplate = (props: FileDetailsObject) => (
        <span>
            <span className={`fa ${getFileIconClass(props.name)}`} style={{ marginRight: '5px' }} />
            {props.name}
        </span>
    );


    const handleRowSelected = (args: any) => {
        const selectedRecords = gridRef.current?.getSelectedRecords() as FileDetailsObject[];
        // Filter out files that are already cut or copied
        const filteredRecords = selectedRecords.filter(
            (record) => !isFileAlreadyCutOrCopied(record?.id)
        );

        if (filteredRecords.length > 0) {
            setSelectedFiles(filteredRecords);
            FileManagerOperations.setSelectedGridViewItems(filteredRecords);
        } else {
            setSelectedFiles([]);
            FileManagerOperations.setSelectedGridViewItems([]);
        }
    };


    const handleRowDeselected = (args: any) => {
        const selectedRecords = gridRef.current?.getSelectedRecords() as FileDetailsObject[];
        if (!selectedRecords || selectedRecords.length === 0) {
            FileManagerOperations.setSelectedGridViewItems([]);
        }
    };

    const handleRowDoubleClick = async (args: RecordDoubleClickEventArgs) => {
        let doubleClickedRowData = args.rowData as FileDetailsObject;
        if (doubleClickedRowData && !doubleClickedRowData.isFile) {
            await FileManagerOperations.fetchTreeViewFolders(doubleClickedRowData.id);
            await FileManagerOperations.setHighlightedItem(doubleClickedRowData);
            //FileManagerOperations.fetchGridViewItems();
        }
    };

    const findAndExpandNode = (nodes: FileDetailsObject[], id: string): FileDetailsObject | null => {
        for (const node of nodes) {
            if (node.id === id) {
                node.isExpanded = true;
                return node;
            }
            if (node.data?.length > 0) {
                const expandedNode = findAndExpandNode(node.data, id);
                if (expandedNode) return expandedNode;
            }
        }
        return null;
    };


    const contextMenuOpen = (args: any) => {
        if (gridRef.current) {
            const contextMenu = gridRef.current.contextMenuModule.contextMenu;

            // Dynamically manipulate the context menu based on your logic
            const currentSelectedFiles = FileManagerOperations.selectedGridViewItems || [];
            const displayVersion = FileManagerOperations.displayVersion || "default";

            let newItems: any = [];
            if (currentSelectedFiles.length === 0) {
                let tempFile = currentSelectedFiles?.at(0);
                if (tempFile?.isFile) {
                    newItems = [
                        { text: "Preview", target: ".e-content", id: "preview", iconCss: "fa fa-eye" },
                        { text: "Edit", target: ".e-content", id: "edit", iconCss: "fa fa-pencil" },
                        { separator: true },
                        { text: "Cut", target: ".e-content", id: "cut", iconCss: "fa fa-scissors" },
                        { text: "Copy", target: ".e-content", id: "copy", iconCss: "fa fa-files-o" },
                        { text: "Paste", target: ".e-content", id: "paste", iconCss: "fa fa-clipboard" },
                        { separator: true },
                        { text: "Delete", target: ".e-content", id: "delete", iconCss: "fa fa-trash-o" },
                        { text: "Download", target: ".e-content", id: "download", iconCss: "fa fa-download" },
                        { text: "Rename", target: ".e-content", id: "rename", iconCss: "fa fa-edit" },
                        { text: "Details", target: ".e-content", id: "details", iconCss: "fa fa-info" },
                    ];
                }
                else {
                    newItems = [
                        { text: "Open", target: ".e-content", id: "open", iconCss: "fa fa-folder-open" },
                        { separator: true },
                        { text: "Cut", target: ".e-content", id: "cut", iconCss: "fa fa-scissors" },
                        { text: "Copy", target: ".e-content", id: "copy", iconCss: "fa fa-files-o" },
                        { text: "Paste", target: ".e-content", id: "paste", iconCss: "fa fa-clipboard" },
                        { separator: true },
                        { text: "Delete", target: ".e-content", id: "delete", iconCss: "fa fa-trash-o" },
                        { text: "Download", target: ".e-content", id: "download", iconCss: "fa fa-download" },
                        { text: "Rename", target: ".e-content", id: "rename", iconCss: "fa fa-edit" },
                        { text: "Details", target: ".e-content", id: "details", iconCss: "fa fa-info" },
                    ];
                }
            } else {
                const isPdf = currentSelectedFiles.every((item) => item.type === "pdf" || item.type === ".pdf");

                if (isPdf && currentSelectedFiles.length > 1) {
                    newItems = displayVersion === "select"
                        ? [
                            { text: "Preview", target: ".e-content", id: "preview", iconCss: "fa fa-eye" },
                            { text: "Merge PDFs", target: ".e-content", id: "merge", iconCss: "e-icons e-merge-pdf" },
                            { text: "Attach", target: ".e-content", id: "attach", iconCss: "fa fa-paperclip" },
                            { separator: true },
                            { text: "Cut", target: ".e-content", id: "cut", iconCss: "fa fa-scissors" },
                            { text: "Copy", target: ".e-content", id: "copy", iconCss: "fa fa-files-o" },
                            { text: "Paste", target: ".e-content", id: "paste", iconCss: "fa fa-clipboard" },
                            { separator: true },
                            { text: "Delete", target: ".e-content", id: "delete", iconCss: "fa fa-trash-o" },
                            { text: "Download", target: ".e-content", id: "download", iconCss: "fa fa-download" },
                        ]
                        : [
                            { text: "Preview", target: ".e-content", id: "preview", iconCss: "fa fa-eye" },
                            { text: "Merge PDFs", target: ".e-content", id: "merge", iconCss: "e-icons e-merge-pdf" },
                            { separator: true },
                            { text: "Cut", target: ".e-content", id: "cut", iconCss: "fa fa-scissors" },
                            { text: "Copy", target: ".e-content", id: "copy", iconCss: "fa fa-files-o" },
                            { text: "Paste", target: ".e-content", id: "paste", iconCss: "fa fa-clipboard" },
                            { separator: true },
                            { text: "Delete", target: ".e-content", id: "delete", iconCss: "fa fa-trash-o" },
                            { text: "Download", target: ".e-content", id: "download", iconCss: "fa fa-download" },
                        ];
                } else if (currentSelectedFiles.length > 1) {
                    newItems = displayVersion === "select"
                        ? [
                            { text: "Cut", target: ".e-content", id: "cut", iconCss: "fa fa-scissors" },
                            { text: "Copy", target: ".e-content", id: "copy", iconCss: "fa fa-files-o" },
                            { text: "Paste", target: ".e-content", id: "paste", iconCss: "fa fa-clipboard" },
                            { text: "Attach", target: ".e-content", id: "attach", iconCss: "fa fa-paperclip" },
                            { separator: true },
                            { text: "Delete", target: ".e-content", id: "delete", iconCss: "fa fa-trash-o" },
                            { text: "Download", target: ".e-content", id: "download", iconCss: "fa fa-download" },
                        ]
                        : [
                            { text: "Cut", target: ".e-content", id: "cut", iconCss: "fa fa-scissors" },
                            { text: "Copy", target: ".e-content", id: "copy", iconCss: "fa fa-files-o" },
                            { text: "Paste", target: ".e-content", id: "paste", iconCss: "fa fa-clipboard" },
                            { separator: true },
                            { text: "Delete", target: ".e-content", id: "delete", iconCss: "fa fa-trash-o" },
                            { text: "Download", target: ".e-content", id: "download", iconCss: "fa fa-download" },
                        ];
                } else {


                    if (displayVersion === "select") {
                        newItems = [
                            { text: "Preview", target: ".e-content", id: "preview", iconCss: "fa fa-eye" },
                            { separator: true },
                            { text: "Cut", target: ".e-content", id: "cut", iconCss: "fa fa-scissors" },
                            { text: "Copy", target: ".e-content", id: "copy", iconCss: "fa fa-files-o" },
                            { text: "Paste", target: ".e-content", id: "paste", iconCss: "fa fa-clipboard" },
                            { text: "Attach", target: ".e-content", id: "attach", iconCss: "fa fa-paperclip" },
                            { separator: true },
                            { text: "Delete", target: ".e-content", id: "delete", iconCss: "fa fa-trash-o" },
                            { text: "Download", target: ".e-content", id: "download", iconCss: "fa fa-download" },
                            { text: "Rename", target: ".e-content", id: "rename", iconCss: "fa fa-edit" },
                            { text: "Details", target: ".e-content", id: "details", iconCss: "fa fa-info" },
                        ]
                    }
                    else {
                        let tempFile = currentSelectedFiles?.at(0);
                        if (tempFile?.isFile) {
                            newItems = [
                                { text: "Preview", target: ".e-content", id: "preview", iconCss: "fa fa-eye" },
                                { text: "Edit", target: ".e-content", id: "edit", iconCss: "fa fa-pencil" },
                                { separator: true },
                                { text: "Cut", target: ".e-content", id: "cut", iconCss: "fa fa-scissors" },
                                { text: "Copy", target: ".e-content", id: "copy", iconCss: "fa fa-files-o" },
                                { text: "Paste", target: ".e-content", id: "paste", iconCss: "fa fa-clipboard" },
                                { separator: true },
                                { text: "Delete", target: ".e-content", id: "delete", iconCss: "fa fa-trash-o" },
                                { text: "Download", target: ".e-content", id: "download", iconCss: "fa fa-download" },
                                { text: "Rename", target: ".e-content", id: "rename", iconCss: "fa fa-edit" },
                                { text: "Details", target: ".e-content", id: "details", iconCss: "fa fa-info" },
                            ]
                        }
                        else {
                            if (tempFile) {
                                newItems = [
                                    { text: "Open", target: ".e-content", id: "open", iconCss: "fa fa-folder-open" },
                                    { separator: true },
                                    { text: "Cut", target: ".e-content", id: "cut", iconCss: "fa fa-scissors" },
                                    { text: "Copy", target: ".e-content", id: "copy", iconCss: "fa fa-files-o" },
                                    { text: "Paste", target: ".e-content", id: "paste", iconCss: "fa fa-clipboard" },
                                    { separator: true },
                                    { text: "Delete", target: ".e-content", id: "delete", iconCss: "fa fa-trash-o" },
                                    { text: "Download", target: ".e-content", id: "download", iconCss: "fa fa-download" },
                                    { text: "Rename", target: ".e-content", id: "rename", iconCss: "fa fa-edit" },
                                    { text: "Details", target: ".e-content", id: "details", iconCss: "fa fa-info" },
                                ]
                            }
                        }

                    }

                }
            }

            // Update the context menu items
            contextMenu.items = newItems;
            contextMenu.dataBind();
            let isPaste = canPaste;
            if (FileManagerOperations.selectedFileItem && !FileManagerOperations.selectedFileItem.isFile) {
                if (!cutOrCopiedFiles.includes(FileManagerOperations.selectedFileItem.id)) {
                    isPaste = true;
                }
            }
            else {
                isPaste = false;
            }
            contextMenu.enableItems(["Paste"], isPaste);
        }
    };


    const contextMenuClick = (args: ContextMenuClickEventArgs) => {

        let encodedFilePath = "";
        let encodedFileName = "";
        let currentFile = FileManagerOperations.selectedFileItem;
        let currentFileId = FileManagerOperations?.selectedFileItem?.id;
        let currentFileName = FileManagerOperations?.selectedFileItem?.name;
        if (!currentFileName) {
            return;
        }

        if (args?.item?.id === 'copy') {
            setCutOrCopiedFiles([]);
            FileManagerOperations.copyItems();
        }

        if (args?.item?.id === 'cut') {
            setCutOrCopiedFiles([]);
            FileManagerOperations.cutItems();
        }

        if (args?.item?.id === 'paste') {
            setCutOrCopiedFiles([]);
        }

        if (args?.item?.id === 'merge') {
            FileManagerOperations.setSelectDialog('merge');
            FileManagerOperations.setDialogOpen();
        }

        if (args?.item?.id === 'delete') {
            FileManagerOperations.deleteSelectedItems();
        }

        if (args?.item?.id === 'details') {
            FileManagerOperations.setSelectDialog('details');
            FileManagerOperations.setDialogOpen();
        }

        if (args?.item?.id === 'rename') {
            FileManagerOperations.setSelectDialog('rename');
            FileManagerOperations.setDialogOpen();
        }

        if (args?.item?.id === 'attach') {
            FileManagerOperations.addToFileAttachement();
        }

        if (args?.item?.id === 'paste') {
            FileManagerOperations.pasteItems();
        }

        if (args?.item?.id === 'download') {
            FileManagerOperations.downloadSelectedItems();
        }

        if (args?.item?.id === 'open') {
            if (FileManagerOperations.selectedFileItem) {
                FileManagerOperations.setSelectedTreeViewItem(FileManagerOperations.selectedFileItem);
            }
        }

        if (args?.item?.text === 'Edit') {

            if (isDocumentProcessorSupported(currentFileName, ProcessorType.PDF, ActionType.Edit)) {
                if (selectedFiles?.length === 1) {
                    if (currentFileId) {
                        encodedFilePath = encodeURIComponent(currentFileId);
                        encodedFileName = encodeURIComponent(currentFileName);
                        window.open(`/pdf-editor?path=${encodedFilePath}&name=${encodedFileName}&provider=${currentFile?.fileProviderId ?? ""}&sessionId=${FileSession.sessionId}&sessionHost=${FileSession.sessionHost}&sessionUid=${FileSession.sessionUid}&sessionB2C=${FileSession.sessionB2C}`, 'pdfEditorTab');
                    }
                }
            }
            else
                if (isDocumentProcessorSupported(currentFileName, ProcessorType.Word, ActionType.Edit)) {
                    if (selectedFiles?.length === 1) {
                        if (currentFileId) {
                            const encodedFilePath = encodeURIComponent(currentFileId);
                            const encodedFileName = encodeURIComponent(currentFileName);

                            window.open(`/word-editor?path=${encodedFilePath}&name=${encodedFileName}&provider=${currentFile?.fileProviderId ?? ""}&sessionId=${FileSession.sessionId}&sessionHost=${FileSession.sessionHost}&sessionUid=${FileSession.sessionUid}&sessionB2C=${FileSession.sessionB2C}`, 'wordEditorTab');
                        }
                    }
                }
                else
                    if (isDocumentProcessorSupported(currentFileName, ProcessorType.Image, ActionType.Edit)) {
                        if (selectedFiles?.length === 1) {
                            if (currentFileId) {
                                const encodedFilePath = encodeURIComponent(currentFileId);
                                const encodedFileName = encodeURIComponent(currentFileName);
                                window.open(`/image-editor?path=${encodedFilePath}&name=${encodedFileName}&provider=${currentFile?.fileProviderId ?? ""}&sessionId=${FileSession.sessionId}&sessionHost=${FileSession.sessionHost}&sessionUid=${FileSession.sessionUid}&sessionB2C=${FileSession.sessionB2C}`, 'imageEditorTab');
                            }
                        }
                    }
                    else
                        if (isDocumentProcessorSupported(currentFileName, ProcessorType.Excel, ActionType.Edit)) {
                            if (selectedFiles?.length === 1) {
                                if (currentFileId) {
                                    const encodedFilePath = encodeURIComponent(currentFileId);
                                    const encodedFileName = encodeURIComponent(currentFileName);
                                    window.open(`/spreadsheet-editor?path=${encodedFilePath}&name=${encodedFileName}&provider=${currentFile?.fileProviderId ?? ""}&sessionId=${FileSession.sessionId}&sessionHost=${FileSession.sessionHost}&sessionUid=${FileSession.sessionUid}&sessionB2C=${FileSession.sessionB2C}`, 'spreadsheetEditorTab');
                                }
                            }
                        }
                        else {
                            if (currentFile?.isFile) {
                                FileManagerOperations.showToastNotification(`File type ${currentFile?.type} is not currently supported`, "File Edit", "Error")
                            }
                        }
        }

        if (args?.item?.id === 'preview' && currentFile?.isFile) {
            let defaultWindowSize = `width=${window.screen.availWidth},height=${window.screen.availHeight}`;
            if (isDocumentProcessorSupported(currentFileName, ProcessorType.PDF, ActionType.Preview)) {
                if (selectedFiles?.length === 1) {
                    if (currentFileId) {
                        window.open(`/viewer?uuid=${encodeURIComponent(currentFile?.uuid)}&sessionId=${FileSession.sessionId}&sessionHost=${FileSession.sessionHost}&sessionUid=${FileSession.sessionUid}&sessionB2C=${FileSession.sessionB2C}`, 'viewerTab', defaultWindowSize);
                    }
                }
            }
            else
                if (isDocumentProcessorSupported(currentFileName, ProcessorType.Word, ActionType.Preview)) {
                    if (selectedFiles?.length === 1) {
                        if (currentFileId) {

                            window.open(`/viewer?uuid=${encodeURIComponent(currentFile?.uuid)}&sessionId=${FileSession.sessionId}&sessionHost=${FileSession.sessionHost}&sessionUid=${FileSession.sessionUid}&sessionB2C=${FileSession.sessionB2C}`, 'viewerTab', defaultWindowSize);
                        }
                    }
                }
                else if (isDocumentProcessorSupported(currentFileName, ProcessorType.Text, ActionType.Preview)) {
                    if (selectedFiles?.length === 1) {
                        if (currentFileId) {
                            window.open(`/viewer?uuid=${encodeURIComponent(currentFile?.uuid)}&sessionId=${FileSession.sessionId}&sessionHost=${FileSession.sessionHost}&sessionUid=${FileSession.sessionUid}&sessionB2C=${FileSession.sessionB2C}`, 'viewerTab', defaultWindowSize);
                        }
                    }
                }
                else if (isDocumentProcessorSupported(currentFileName, ProcessorType.Image, ActionType.Preview)) {
                    if (selectedFiles?.length === 1) {

                        if (currentFileId) {
                            window.open(`/viewer?uuid=${encodeURIComponent(currentFile?.uuid)}&sessionId=${FileSession.sessionId}&sessionHost=${FileSession.sessionHost}&sessionUid=${FileSession.sessionUid}&sessionB2C=${FileSession.sessionB2C}`, 'viewerTab', defaultWindowSize);
                        }
                    }
                }
                else if (isDocumentProcessorSupported(currentFileName, ProcessorType.Excel, ActionType.Preview)) {
                    if (selectedFiles?.length === 1) {
                        if (currentFileId) {
                            window.open(`/viewer?uuid=${encodeURIComponent(currentFile?.uuid)}&sessionId=${FileSession.sessionId}&sessionHost=${FileSession.sessionHost}&sessionUid=${FileSession.sessionUid}&sessionB2C=${FileSession.sessionB2C}`, 'viewerTab', defaultWindowSize);
                        }
                    }
                }
                else {

                    if (currentFile?.isFile) {
                        FileManagerOperations.showToastNotification(`File type ${currentFile?.type} is not currently supported`, "File Preview", "Error")
                    }
                }
        }
    }


    const sizeTemplate = (props: FileDetailsObject) => <span>{formatSize(props.size, !props.isFile)}</span>;
    const dateTemplate = (props: FileDetailsObject) => (
        <span>{formatDate(props.dateModified)}</span>
    );

    const handleDragStart = (e: React.DragEvent<HTMLTableRowElement>, rowIndex: number) => {
        // Get all selected IDs (for multi-select drag)
        const selectedIds = gridRef.current?.getSelectedRecords() as FileDetailsObject[];

        // Set JSON data with selected items
        e.dataTransfer.setData('application/json', JSON.stringify({
            type: 'grid',
            id: selectedIds.length > 0 ? selectedIds : [gridData[rowIndex].id]
        }));
        e.currentTarget.style.opacity = '0.5';
    };


    const handleFileDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const files = Array.from(e.dataTransfer.files);
            const currentFile = FileManagerOperations.selectedFileItem;

            if (!currentFile?.isFile && currentFile) {
                FileManagerOperations.showToastNotification('Uploading file(s) in the background', 'File Upload', 'Information');
                currentFile.uploadFiles = files;

                try {
                    const response = await fileUploadService(currentFile, FileSession);
                    console.log('Upload successful:', response);
                    FileManagerOperations.showToastNotification('File(s) uploaded successfully!', 'File Upload', 'Success');
                    FileManagerOperations.refreshFiles(currentFile);
                } catch (error) {
                    console.error('Upload failed:', error);
                    FileManagerOperations.showToastNotification('Upload failed', 'File Upload', 'Error');
                }
            } else {
                FileManagerOperations.showToastNotification('Please select a folder before uploading files.', 'File Upload', 'Warning');
            }
        }
    };




    const handleDragEnd = (e: React.DragEvent<HTMLTableRowElement>) => {
        // Reset styles when dragging ends (even if not dropped)
        e.currentTarget.style.opacity = '';
        e.currentTarget.style.backgroundColor = '';
    };

    const handleDragOver = (e: React.DragEvent<HTMLTableRowElement>) => {
        e.preventDefault();
        const targetRow = e.currentTarget as HTMLTableRowElement;
        targetRow.style.backgroundColor = '#f0f0f0'; // Use a proper color value
    };

    const handleDragLeave = (e: React.DragEvent<HTMLTableRowElement>) => {
        const targetRow = e.currentTarget as HTMLTableRowElement;
        targetRow.style.backgroundColor = '';
    };

    const handleDrop = async (e: React.DragEvent<HTMLTableRowElement>, targetIndex: number) => {
        e.preventDefault();
        const targetRow = e.currentTarget;
        targetRow.style.backgroundColor = '';
        debugger;
        // Scenario 2: Internal grid move
        try {
            const data = JSON.parse(e.dataTransfer.getData('application/json'));
            if (data.type === 'grid') {
                const targetItem = gridData[targetIndex];

                // Verify target is a folder
                if (targetItem.isFile) {
                    //FileManagerOperations.showToastNotification('Cannot drop into a file', 'Move', 'Warning');
                    return;
                }

                // Get dragged items
                const draggedItems = gridData.filter(item =>
                    data.ids.includes(item.id)
                );

                // Perform move operation
                await FileManagerOperations.moveFileDetailsObjects(draggedItems, targetItem);

                // Refresh grid
                FileManagerOperations.fetchGridViewItems();
            }
        } catch (error) {
            console.error('Drop error:', error);
            FileManagerOperations.showToastNotification('Move failed', 'Move', 'Error');
        }
    };



    const handleGridDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        // Scenario 1: File upload from OS
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const files = Array.from(e.dataTransfer.files);
            const targetFolder = FileManagerOperations.selectedFileItem;

            if (targetFolder && !targetFolder.isFile) {
                FileManagerOperations.showToastNotification('Uploading file(s)...', 'File Upload', 'Information');
                try {
                    //await FileManagerOperations.uploadFilesToFolder(targetFolder, files, FileSession);
                    //FileManagerOperations.showToastNotification('File(s) uploaded successfully!', 'File Upload', 'Success');
                    //FileManagerOperations.refreshFiles(targetFolder);
                } catch (error) {
                    console.error('Upload failed:', error);
                    FileManagerOperations.showToastNotification('Upload failed', 'File Upload', 'Error');
                }
            } else {
                FileManagerOperations.showToastNotification('Please select a folder before uploading files.', 'File Upload', 'Warning');
            }
        }
        // Scenario 2: Internal grid move operation
        else if (e.dataTransfer.types.includes('application/json')) {
            const data = JSON.parse(e.dataTransfer.getData('application/json'));
            const draggedItems = data.ids.map((id: string) =>
                gridData.find(item => item.id === id)
            ).filter(Boolean);

            const targetElement = document.elementFromPoint(e.clientX, e.clientY)?.closest('.e-row');
            const targetData = targetElement?.getAttribute('data-uid') ?
                gridData.find(item => item.id === targetElement?.getAttribute('data-uid')) :
                null;
            debugger;
            if (targetData && !targetData.isFile) {
               
                try {
                    await FileManagerOperations.moveFileDetailsObjects(draggedItems, targetData);
                    FileManagerOperations.fetchGridViewItems();
                } catch (error) {
                    FileManagerOperations.showToastNotification('Move operation failed', 'Move Items', 'Error');
                }
            } else {
                FileManagerOperations.showToastNotification('Cannot drop items here - target must be a folder', 'Move Items', 'Warning');
            }
        }
    };


    const rowDataBound = (args: RowDataBoundEventArgs) => {
        if (!args.row) return;

        // Cast to HTMLElement to access draggable and style properties
        const row = args.row as HTMLElement;
        // Get index from gridData instead of args
        const rowIndex = gridData.findIndex(item => item.id === (args.data as FileDetailsObject)?.id);

        // Clean up existing listeners
        const cleanUpListeners = () => {
            row.removeEventListener('dragstart', handleDragStart as any);
            row.removeEventListener('dragover', handleDragOver as any);
            row.removeEventListener('dragleave', handleDragLeave as any);
            row.removeEventListener('dragend', handleDragEnd as any);
            row.removeEventListener('drop', handleDrop as any);
        };

        cleanUpListeners();

        // Add new listeners with proper typing
        row.draggable = true;
        row.addEventListener('dragstart', (e) => handleDragStart(e as unknown as React.DragEvent<HTMLTableRowElement>, rowIndex));
        row.addEventListener('dragover', handleDragOver as any);
        row.addEventListener('dragleave', handleDragLeave as any);
        row.addEventListener('dragend', handleDragEnd as any);
        row.addEventListener('drop', (e) => handleDrop(e as unknown as React.DragEvent<HTMLTableRowElement>, rowIndex));

        // Apply styling with proper type assertion
        if (cutOrCopiedFiles.includes((args.data as FileDetailsObject)?.id)) {
            row.style.pointerEvents = 'none';
            row.style.opacity = '0.5';
            row.style.color = 'red';
        }
    };

    return (
        <div style={{ height: '90vh', background: 'white', padding: '0 0px', overflowY: 'hidden' }} onDrop={handleFileDrop}
            >
            <GridComponent
                dataSource={gridData}
                allowPaging={true}
                allowResizing={true}
                allowSorting={true}
                allowFiltering={true}
                searchSettings={{ fields: ['name', 'type', 'dateModified', 'size'] }}
                allowMultiSorting={true}
                editSettings={{ allowEditing: false, allowDeleting: false, allowAdding: false }}
                filterSettings={{ type: 'Excel' }}
                selectionSettings={{
                    type: 'Multiple', // Allows multiple rows selection
                    mode: 'Row', // Use 'Row' selection
                    checkboxOnly: false, // Restrict selection through checkboxes only
                    persistSelection: false, // Persist the selection across operations
                    checkboxMode: 'ResetOnRowClick', // Default checkbox selection mode
                }}
                contextMenuItems={[
                    { text: "Preview", target: ".e-content", id: "preview", iconCss: "fa fa-eye" },
                    { text: "Edit", target: ".e-content", id: "edit", iconCss: "fa fa-pencil" },
                    { separator: true },
                    { text: "Cut", target: ".e-content", id: "cut", iconCss: "fa fa-scissors" },
                    { text: "Copy", target: ".e-content", id: "copy", iconCss: "fa fa-files-o" },
                    { separator: true },
                    { text: "Delete", target: ".e-content", id: "delete", iconCss: "fa fa-trash-o" },
                    { text: "Download", target: ".e-content", id: "download", iconCss: "fa fa-download" },
                    { text: "Rename", target: ".e-content", id: "rename", iconCss: "fa fa-edit" },
                    { text: "Details", target: ".e-content", id: "details", iconCss: "fa fa-info" },
                ]}
                contextMenuOpen={contextMenuOpen}
                contextMenuClick={contextMenuClick}
                height="100%"
                sortSettings={{
                    columns: [
                        { field: 'type', direction: 'Ascending' },
                        { field: 'name', direction: 'Ascending' },
                    ],
                }}
                actionComplete={(args) => {
                    if (args.requestType === 'rowDoubleClick') {
                        handleRowDoubleClick(args);
                    }
                }}

                recordDoubleClick={handleRowDoubleClick}
                rowDataBound={rowDataBound}
                rowSelected={handleRowSelected}
                rowDeselected={handleRowDeselected}
                pageSettings={pageSettings}
                ref={gridRef}
            >
                <ColumnsDirective>
                    <ColumnDirective
                        type="checkbox" // Adds the checkbox column
                        width="50" // Width of the checkbox column
                    />
                    <ColumnDirective
                        field="name"
                        headerText="Name"
                        width="auto"
                        textAlign="Left"
                        isPrimaryKey={true}
                        allowSorting={true}
                        allowFiltering={true}
                        allowResizing={true}
                        template={fileIconTemplate}
                    />
                    <ColumnDirective
                        field="dateModified"
                        headerText="Date Modified"
                        width="150"
                        minWidth="120"
                        textAlign="Left"
                        allowSorting={true}
                        allowResizing={true}
                        allowFiltering={false}
                        template={dateTemplate}
                    />
                    <ColumnDirective
                        field="type"
                        headerText="Type"
                        width="120"
                        minWidth="100"
                        textAlign="Left"
                        allowFiltering={false}
                        allowSorting={true}
                        allowResizing={true}
                    />
                    <ColumnDirective
                        field="size"
                        headerText="Size"
                        width="auto" // Stretch this column to fill remaining space
                        minWidth="100"
                        textAlign="Right"
                        allowSorting={true}
                        allowFiltering={false}
                        allowResizing={false}
                        template={sizeTemplate}
                    />
                </ColumnsDirective>
                <Inject services={[Page, Filter, Edit, ContextMenu, RowDD, Group, Toolbar, Sort, Resize]} />
            </GridComponent>
        </div>
    );
}