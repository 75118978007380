import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import FileManagerOperations from '../Services/FileManagerOperations';
import { FileSession } from '../../../models/FileSession';
import { fileUploadService } from '../../../apis/fileService';

interface Props {
    FileManagerOperations: FileManagerOperations;
    FileSession: FileSession;
}

export interface FileUploaderRef {
    triggerFileDialog: () => void;
}

const FileUploader = forwardRef<FileUploaderRef, Props>(({ FileManagerOperations, FileSession }, ref) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    // Expose triggerFileDialog to parent
    useImperativeHandle(ref, () => ({
        triggerFileDialog: () => {
            fileInputRef.current?.click(); // Programmatically open file input dialog
        },
    }));

    // Handle file selection and upload
    const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const files = Array.from(e.target.files);
            const currentFile = FileManagerOperations.selectedFileItem;

            if (!currentFile?.isFile && currentFile) {
                FileManagerOperations.showToastNotification( 'Uploading file(s) in the background','File Upload', 'Information');
                currentFile.uploadFiles = files;
                try {
                    const response = await fileUploadService(currentFile, FileSession);
                    console.log('Upload successful:', response);
                    FileManagerOperations.showToastNotification('File(s) uploaded successfully!','File Upload', 'Success');
                    FileManagerOperations.refreshFiles(currentFile);
                } catch (error) {
                    console.error('Upload failed:', error);
                    FileManagerOperations.showToastNotification( 'Upload failed', 'File Upload', 'Error');
                }
            }
        }
    };

    return (
        <>
            {/* Hidden File Input */}
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                multiple
                onChange={handleFileUpload}
            />
        </>
    );
});

export default FileUploader;