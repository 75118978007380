import { Annotation, BookmarkView, FormDesigner, FormFields, Inject, LinkAnnotation, Magnification, Navigation, PageOrganizer, PdfViewerComponent, Print, TextSearch, TextSelection, ThumbnailView, Toolbar } from '@syncfusion/ej2-react-pdfviewer';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fileOpenService, fileSaveService, fileSplitPDFService } from '../../../apis/fileService';
import { STANDALONE_PDF_SERVICE_URL } from '../../../environment/env_dev';
import { FileSession } from '../../../models/FileSession';
import { SaveFileRequest } from '../../../models/SaveFileRequest';
import { SplitPdfFile } from '../../../models/SplitPdfFile';
import NavBar from '../../NavBar';
import ToastNotification from '../Notifications/ToastNotification';
import FileManagerOperations from '../Services/FileManagerOperations';
import './PdfManagerPage.scss';

interface Props {
    fileSession: FileSession;
    FileManagerOperations: FileManagerOperations;
}

export const FileManagerPDFEditor = ({ fileSession, FileManagerOperations }: Props) => {
    //const pdfjs = require('pdfjs-dist');
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const pdfPath = queryParams.get('path') ?? "";
    const fileName = queryParams.get('name') ?? "";
    const fileId = queryParams.get('name') ?? "";
    const fileProvider = queryParams.get('provider') ?? "";
    const [saveSplitChanges, setSaveSplitChanges] = useState<boolean>(false);
    const [checked, setChecked] = React.useState<number[]>();
   
    const [isDocumentBusy, setIsDocumentBusy] = useState<boolean>(false);
    const [base64FileString, setBase64FileString] = useState<string>();
    const pdfViewer = useRef<PdfViewerComponent>(null);


    useEffect(() => {

        if (isDocumentBusy) {
            FileManagerOperations.showToastNotification("Please wait...", "Document Loading", "Information");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDocumentBusy]);


    useEffect(() => {
        async function loadFileFromServer() {
            try {
                // make a fetch request to the action method URL
                const result = await fileOpenService(pdfPath, fileName, fileProvider ?? "", fileSession);
                var data = result?.data?.Data;
                if (data) {
                    setBase64FileString(data.FileBase64String);
                }
            } catch (error) {
                // handle any errors that may occur
                console.log('There has been a problem with your fetch operation: ', error);
            }
        }

        loadFileFromServer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Saves data when saveSplitChanges is changed
    useEffect(() => {

        async function fetchSelectedFileContent() {
            if (checked && checked.length > 0 && saveSplitChanges === true) {

                FileManagerOperations.showToastNotification("Splitting the pages. Please wait...", "Split Document", "Information");
                var currentName = fileName;
                var currentNameWithoutExt = currentName.substring(0, currentName.lastIndexOf("."));
                let checkedPages: string[] = [];
                let checkedPagesNames: string[] = [];
                let counter = 0;
                checked.sort().forEach((a) => {
                    counter++;
                    checkedPages.push((a + 1).toString());
                    checkedPagesNames.push(`${currentNameWithoutExt}-${counter}`);
                });
                checkedPagesNames.push(`${currentNameWithoutExt}-${counter + 1}`);
                const splitPdfFileRequest = new SplitPdfFile(currentName, pdfPath, checkedPages, checkedPagesNames);

                if (checkedPages && checkedPages.length > 0) {
                    try {
                        const result = await fileSplitPDFService(splitPdfFileRequest, fileProvider, fileSession);
                        if (result.data) {
                            if (result.data.Success) {
                                FileManagerOperations.showToastNotification("File splitted successfully", "Split Document", "Success");
                                //window.location.assign('/');
                                window.location.href = '/'
                            }
                            else {
                                FileManagerOperations.showToastNotification("An error occurred. Please refresh and try again", "Split Document", "Error");
                            }
                        }

                    } catch (e) {
                    }
                }
                else {
                    FileManagerOperations.showToastNotification("Please select pages to split", "Split Document", "Warning");
                }
            }
        }
        fetchSelectedFileContent().then(
            () => {
                setSaveSplitChanges(false);
            }
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveSplitChanges]);


    const savePendingChanges = () => {
        async function doAsyncFunction() {
            setIsDocumentBusy(true);
            try {
                if (pdfViewer.current) {
                    FileManagerOperations.showToastNotification("Saving changes. Please wait...", "Saving Changes", "Information");
                    const blob: Blob = await pdfViewer.current.saveAsBlob();

                    // Convert Blob to base64
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);

                    reader.onloadend = async () => {
                        const base64data = reader.result as string;
                        // Update state with new base64 string
                        //setBase64FileString(base64data); // Remove data URL prefix

                        let saveFileRequest = new SaveFileRequest();
                        saveFileRequest.base64String = base64data;
                        saveFileRequest.id = pdfPath;
                        saveFileRequest.name = fileName;
                        saveFileRequest.providerId = fileProvider;
                        const result = await fileSaveService(saveFileRequest, fileSession);
                        var data = result?.data?.Data;
                        if (data || result?.data?.Success) {
                            FileManagerOperations.showToastNotification("Changes were saved successfully.", "Saving Changes", "Success");
                        } else {
                            FileManagerOperations.showToastNotification("An error occurred while saving the changes. Please try again!", "Saving Changes", "Error");
                        }
                    };
                }

            } catch (e) {
                FileManagerOperations.showToastNotification("Error occurred while saving changes", "Edit Document", "Error");
            }
        }
        doAsyncFunction().then(
            () => {
                setIsDocumentBusy(false);
            }
        );
    }

    return (
        <div >
            <NavBar filePath={pdfPath} fileName={fileName} saveFunction={() => { savePendingChanges(); }
            } saveAsFunction={() => {
                }} cancelFunction={() => {
                if (window.opener) {
                    window.close();
                } else {
                    window.location.href = 'about:blank';
                    window.close();
                }
            }}
                closeFunction={() => {
                    if (window.opener) {
                        window.close();
                    } else {
                        window.location.href = 'about:blank';
                        window.close();
                    }
                }} />
            <div id="pdf-editor-page" style={{ display: 'flex', flexDirection: 'column', height: `calc(100vh - 90px)` }}>
                {
                    base64FileString &&
                    <PdfViewerComponent
                        id="container"
                        ref={pdfViewer}
                        documentPath={base64FileString}
                        resourceUrl={STANDALONE_PDF_SERVICE_URL}
                        height='90vh'>
                        <Inject services={[Toolbar, Magnification, Navigation, Annotation, LinkAnnotation, BookmarkView, ThumbnailView,
                            Print, TextSelection, TextSearch, FormFields, FormDesigner, PageOrganizer]} />
                    </PdfViewerComponent>
                }
                
            </div>

            <ToastNotification />
        </div>
    )
};